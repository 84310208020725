/**
 * FootnoteContext
 *
 * A context and functions for creating footnote references in the page.
 *
 * Feature:
 *
 * - Collect all footnotes from any where of the page.
 * - Footnotes are numbered globally.
 * - Collected footnotes can be displayed anywhere.
 *
 * Usage:
 *
 * 1. Collect footnotes in modules using `registerFootnote()`. Example:
 * ```
 * const { registerFootnote, getFootnote } = React.useContext(FootnoteContext);
 *
 * // registerFootnote must be done as an effect.
 * React.useEffect(() => {
 *  for (const footnote in data.footnotes) {
 *    registerFootnote(footnote.key, footnote)
 *  }
 * }, [data, registerFootnote]);
 *
 * // When rendering, call `getFootnote()` to get the footnote number.
 * return (
 *   <ul>
 *     {data.footnotes.map(footnote => {
 *        const entry = getFootnote(key);
 *        return <li key={entry.key}>Footnote [${entry.num}]</li>;
 *      }
 *     )}
 *   </ul>
 * )
 *
 * 2. Call `getAllFootnotes()` when displaying the footnotes at the bottom of the page.
 *    See `FootnoteList.tsx` for example.
 *
 * 3. Use `createAnchor()` and `createReverseAnchor()` to create link anchors.
 *    `createAnchor()` creates anchors for use in the bottom section (for jumping from the content to the footnotes),
 *    `createReverseAnchor()` creates anchors for use in the content (for jumping back to the content from the footnotes).
 *
 * ```
 *
 */
import * as React from 'react';

export type FootnoteContextState = {
  getFootnote: (key: string) => FootnoteRegistryEntry;
  getAllFootnotes: () => Array<FootnoteRegistryEntry>;
  registerFootnote: (key: string, footnote: string) => void;
};

export type FootnoteRegistryEntry = {
  key: string;
  num: number;
  footnote: string;
};

type FootnoteRegistry = {
  keys: Array<string>; // array of key
  entries: Record<string, FootnoteRegistryEntry>;
};

export const FootnoteContext = React.createContext<FootnoteContextState>({} as FootnoteContextState);

export const FootnoteProvider: React.FC = ({ children }) => {
  const [footnoteRegistry, setFootnoteRegistry] = React.useState<FootnoteRegistry>({ keys: [], entries: {} });

  const registerFootnote = (key: string, footnote: string) => {
    setFootnoteRegistry((prevState: FootnoteRegistry) => {
      if (prevState.entries[key]) {
        return prevState;
      } else {
        const num = prevState.keys.length + 1;
        return {
          keys: [...prevState.keys, key],
          entries: {
            ...prevState.entries,
            [key]: {
              key,
              num,
              footnote,
            },
          },
        };
      }
    });
  };

  // const getFootnotes = () => footnotes.current;
  const getFootnote = (key: string) => footnoteRegistry.entries[key];

  const getAllFootnotes = () => footnoteRegistry.keys.map((k) => footnoteRegistry.entries[k]);

  return (
    <FootnoteContext.Provider value={{ getFootnote, registerFootnote, getAllFootnotes }}>
      {children}
    </FootnoteContext.Provider>
  );
};

export function createAnchor(num: number) {
  return `footnote-${num}`;
}

export function createReverseAnchor(num: number) {
  return `footnote-ref-${num}`;
}
