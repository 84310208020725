import * as React from 'react';

import { compose } from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import Retracked from 'js/app/retracked';
import connectToRouter from 'js/lib/connectToRouter';

import type { Stores } from 'bundles/ent-website/appEnv';
import { EntWebsiteProvider } from 'bundles/ent-website/components/EntWebsiteContext';
import { FootnoteProvider } from 'bundles/ent-website/components/FootnoteContext';
import { NativeDataProvider } from 'bundles/ent-website/components/NativeDataContext';
import { eventingTransform } from 'bundles/ent-website/utils/eventingTransform';

import 'css!../__styles__/index';

export type RouterProps = { pathname: string };

type Props = {
  requestCountryCode?: string | null;
  csrfToken?: string | null;
};

const EntWebsiteApp: React.FC<Props> = ({ children }) => (
  <EntWebsiteProvider>
    <FootnoteProvider>
      <NativeDataProvider>
        <div className="rc-EntWebsiteApp">{children}</div>
      </NativeDataProvider>
    </FootnoteProvider>
  </EntWebsiteProvider>
);

export default compose(
  connectToStores<Props, {}, Stores>(['ApplicationStore'], ({ ApplicationStore }, props) => ({
    ...props,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  })),
  connectToRouter<RouterProps>((router) => {
    return {
      pathname: router.location.pathname,
    };
  }),
  Retracked.createTrackedContainer<RouterProps>(({ pathname }) => {
    const namespace = {
      app: 'ent_website',
      page: eventingTransform(pathname),
    };

    return {
      namespace,
    };
  })
)(EntWebsiteApp);
