import * as React from 'react';
import { Route } from 'react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import EntWebsiteApp from 'bundles/ent-website/components/EntWebsiteApp';
import NotFound from 'bundles/phoenix/components/NotFound';

const DevDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/DevDomain'));
const RootDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/RootDomain'));
const C4CDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/C4CDomain'));
const SkillsReportsDomain = loadOnRoute(
  () => import('bundles/ent-website/components/RouteWrappers/SkillsReportsDomain')
);
const C4TDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/C4TDomain'));
const C4BHomepage = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/C4BHomepage'));
const C4BDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/C4BDomain'));
const C4CHomepage = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/C4CHomepage'));
const C4CDomainWithoutSlugOverride = loadOnRoute(
  () => import('bundles/ent-website/components/RouteWrappers/C4CDomainWithoutSlugOverride')
);
const C4GHomepage = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/C4GHomepage'));
const C4GDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/C4GDomain'));
const PartnersHomepage = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/PartnersHomepage'));
const PartnersDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/PartnersDomain'));

const SocialImpactHomepage = loadOnRoute(
  () => import('bundles/ent-website/components/RouteWrappers/SocialImpactHomepage')
);
const SocialImpactDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/SocialImpactDomain'));

const EnterpriseDomain = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/EnterpriseDomain'));
const EnterpriseHomepage = loadOnRoute(() => import('bundles/ent-website/components/RouteWrappers/EnterpriseHomepage'));

/*
These routes are temporarily namespaced under ent-website because they can conflict with existing pages.
We can sort out the details as we define what will be released.
*/
export default (
  <Route path="/" component={EntWebsiteApp}>
    {/* entWebsite-testPage is a sample slug */}
    <Route path="ent-website/*" getComponent={DevDomain} />

    {/* Jeff's personal project - does not go under c4b, c4g or c4c. Created new domain `root` because pages live at root path `/` */}
    <Route path="hiring-solutions" getComponent={RootDomain} />
    <Route path="thank-you-hiring-solutions" getComponent={RootDomain} />
    <Route path="hiring-solutions-signup" getComponent={RootDomain} />
    <Route path="skills-reports/global" getComponent={RootDomain} />
    <Route path="skills-reports/global/get-report" getComponent={RootDomain} />
    <Route path="skills-reports/global/thank-you" getComponent={RootDomain} />
    <Route path="skills-reports/global/get-report/pdf/gsr-2023" getComponent={RootDomain} />

    <Route path="skills-reports/campus" getComponent={C4CDomain} />
    <Route path="skills-reports/campus/executive-summary" getComponent={C4CDomain} />
    <Route path="skills-reports/campus/methodology" getComponent={C4CDomain} />
    <Route path="skills-reports/campus/get-report" getComponent={C4CDomain} />
    <Route path="skills-reports/campus/thank-you" getComponent={C4CDomain} />

    <Route path="skills-reports/*" getComponent={SkillsReportsDomain} />

    {/* Using the business prefix to handle the C4B domain. */}
    <Route path="business/teams" getComponent={C4TDomain} />

    <Route path="business" getComponent={C4BHomepage} />
    <Route path="business/*" getComponent={C4BDomain} />

    <Route path="campus" getComponent={C4CHomepage} />
    <Route path="campus/*" getComponent={C4CDomainWithoutSlugOverride} />

    <Route path="enterprise" getComponent={EnterpriseHomepage} />
    <Route path="enterprise/*" getComponent={EnterpriseDomain} />

    {/* Using the government prefix to handle the C4B domain. */}
    <Route path="government" getComponent={C4GHomepage} />
    <Route path="government/*" getComponent={C4GDomain} />

    <Route path="partnerships" getComponent={PartnersHomepage} />
    <Route path="partnerships/*" getComponent={PartnersDomain} />

    <Route path="social-impact" getComponent={SocialImpactHomepage} />
    <Route path="social-impact/*" getComponent={SocialImpactDomain} />
    {/* This catch-all *, status=404 route is important for SSR. */}
    <Route path="*" component={NotFound} />
  </Route>
);
